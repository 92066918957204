html {
    /* overflow: scroll;
    overflow-x: hidden; */
}
::-webkit-scrollbar {
    height: 0;  /*Remove scrollbar space */
    /* width: '3px' */
    /* background: transparent;  Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: darkseagreen;
}